import React, { useState, useEffect } from 'react';
import { Card, Grid, CardContent, Typography, CardMedia, FormControlLabel, CardActions, Button } from '@mui/material';
import DOMPurify from 'dompurify';
import moment from 'moment-timezone';
import { Box } from '@mui/system';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from 'react-router-dom';

const Blog = ({ title, content, imageUrl, start_date, slug, blogurl }) => {

  const [truncatedContent, setTruncatedContent] = useState('');
  const [clientTimezone, setClientTimezone] = useState('');

  useEffect(() => {
    if ('Intl' in window && Intl.Segmenter) {
      // Use Intl.Segmenter for language-aware truncation
      const segmenter = new Intl.Segmenter('en', { granularity: 'word' });
      const segments = [...segmenter.segment(content)].slice(0, 40); // Adjust word limit here
      const truncated = segments.map(segment => segment.segment).join('');
      setTruncatedContent(truncated);
    } else {
      // Fallback for unsupported browsers
      const truncated = content.split(' ').slice(0, 16).join(' ');
      setTruncatedContent(truncated);
    }
    // Get the client's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setClientTimezone(timezone);
  }, [content]);

  // Define your gradient colors here
  const gradient = "linear-gradient(135deg, rgba(70,130,180,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%)"; // Light grey to black



  // Set background color for blank space in the image
  const backgroundColor = '#000'; // Change this to the desired color

  // Function to preprocess image URL
  const preprocessImageUrl = (url) => {
    // Check if URL exists
    if (!url) return null;

    // Return processed URL
    return `${url}?bgcolor=${encodeURIComponent(backgroundColor)}`;
  };

  // Define truncateTitle function
  const truncateTitle = (title) => {
    // Define maximum number of characters allowed for two lines
    const maxChars = 40; // Adjust this value according to your preference
    // If the title length exceeds the limit
    if (title.length > maxChars) {
      // Truncate the title to the nearest word within the limit
      const truncatedTitle = title.substr(0, title.lastIndexOf(' ', maxChars)) + '...';
      return truncatedTitle;
    }
    // Otherwise, return the original title
    return title;
  };

   // Function to format the start_date in the client's timezone
   const formatStartDate = (date) => {
    if (!date || !clientTimezone) return '';
    return moment(date).tz(clientTimezone).format('DD MMMM YYYY');
  };

  return (
    <Grid item xs={12} md={4}>
      <Link to={blogurl} target="_blank" style={{ textDecoration: 'none' }}>
        <Card sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          overflow: "hidden",
          background: gradient, // Apply the gradient background
        }}>
          <CardMedia
            sx={{ height: 200 }}
            image={preprocessImageUrl(imageUrl)}
            title="title"
          />
          <CardContent sx={{ paddingBottom: 0 }}>
            <Typography gutterBottom variant="h6" component="p" sx={{ color: 'white', marginBottom: 1 }}>
            {start_date ? formatStartDate(start_date) : ""}
            </Typography>
            <Typography gutterBottom variant="h5" component="div" sx={{ color: 'white', marginBottom: 1, fontWeight: 700 }}>
              {truncateTitle(title)}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#ccc', wordWrap: 'break-word', marginBottom: 1 }}>
              <Box dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncatedContent + (content.split(' ').length > 20 ? '...' : '')) }} />
            </Typography>
          </CardContent>
          <CardActions sx={{ paddingTop: 0, pl: 1, pr: 1 }}>
            <Typography sx={{ color: 'white', ml: 1.2 }}>READ ARTICLE</Typography>
            <ArrowOutwardIcon sx={{ color: "white" }} />
          </CardActions>

        </Card>
      </Link>
    </Grid>
  );
};

export default Blog;